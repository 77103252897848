import { useSelector, useDispatch } from "react-redux";
import {
  beaconUpdateValue,
  updateChangeTo,
  updateTableView
} from "../../../redux/beacons/update/getOneBeacon";
import { DataGrid, GridRowModes } from "@mui/x-data-grid";
import { useState, useCallback } from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { GridActionsCellItem } from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/DeleteOutlined';

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: "0px solid #E0E0E0",
  borderRadius: "8px",
  boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
  "& .MuiDataGrid-columnHeaders": {
    backgroundColor: "#F5F5F5",
    color: "#333333",
    fontWeight: "bold",
    borderBottom: "1px solid #E0E0E0",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "bold",
    textTransform: "uppercase",
  },
  "& .MuiDataGrid-cell": {
    borderBottom: "1px solid #E0E0E0",
    padding: "8px 16px",
  },
  "& .MuiDataGrid-row": {
    minHeight: "60px",
    "&:nth-of-type(odd)": {
      backgroundColor: "#FAFAFA",
    },
  },
  "& .MuiDataGrid-footerContainer": {
    borderTop: "1px solid #E0E0E0",
  },
  "& .MuiPaginationItem-root": {
    borderRadius: "50%",
    margin: "0 4px",
  },
}));

const CustomNoRowsOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .no-rows-primary": {
    fill: theme.palette.mode === "light" ? "#AEB8C2" : "#3D4751",
  },
  "& .no-rows-secondary": {
    fill: theme.palette.mode === "light" ? "#E8EAED" : "#1D2126",
  },
}));

function CustomNoRowsComponent() {
  return (
    <CustomNoRowsOverlay>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width={96}
        viewBox="0 0 452 257"
        aria-hidden
        focusable="false"
      >
        <path
          className="no-rows-primary"
          d="M348 69c-46.392 0-84 37.608-84 84s37.608 84 84 84 84-37.608 84-84-37.608-84-84-84Zm-104 84c0-57.438 46.562-104 104-104s104 46.562 104 104-46.562 104-104 104-104-46.562-104-104Z"
        />
        <path
          className="no-rows-primary"
          d="M308.929 113.929c3.905-3.905 10.237-3.905 14.142 0l63.64 63.64c3.905 3.905 3.905 10.236 0 14.142-3.906 3.905-10.237 3.905-14.142 0l-63.64-63.64c-3.905-3.905-3.905-10.237 0-14.142Z"
        />
        <path
          className="no-rows-secondary"
          d="M0 10C0 4.477 4.477 0 10 0h380c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 20 0 15.523 0 10ZM0 59c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10C4.477 69 0 64.523 0 59ZM0 106c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 153c0-5.523 4.477-10 10-10h195.5c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 200c0-5.523 4.477-10 10-10h203c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10ZM0 247c0-5.523 4.477-10 10-10h231c5.523 0 10 4.477 10 10s-4.477 10-10 10H10c-5.523 0-10-4.477-10-10Z"
        />
      </svg>
      <Box sx={{ mt: 2 }}>No rows</Box>
    </CustomNoRowsOverlay>
  );
}

export default function BeaconUpdateContainer() {
  const dispatch = useDispatch();
  const changeToData = useSelector(beaconUpdateValue);
  const [rowModesModel, setRowModesModel] = useState({});

  // Process row update and update Redux state
  const processRowUpdate = useCallback(
    (newRow) => {
      // Create a copy of changeToData to avoid mutation
      const updatedChangeTo = { ...changeToData };

      // If the variableName has changed, delete the old key
      if (newRow.variableName !== newRow.id) {
        delete updatedChangeTo[newRow.id];
      }

      // Add or update the new key-value pair
      updatedChangeTo[newRow.variableName] = {
        value: newRow.value,
        fieldType: newRow.fieldType,
      };

      // Dispatch the updated state to Redux
      dispatch(updateChangeTo(updatedChangeTo));

      // Update the row mode to "view" after saving changes
      setRowModesModel((prev) => ({
        ...prev,
        [newRow.id]: { mode: GridRowModes.View },
      }));

      return newRow;
    },
    [dispatch, changeToData]
  );

  // Create formatted table data from Redux state while filtering out "market" key
  const formattedTableData = Object.entries(changeToData)
  .filter(([key]) => key !== "market")
  .map(([key, value]) => ({
    id: key,
    variableName: key,
    value: value === null ? "" : value.value,
    fieldType:  value === null ? "" : value.fieldType,
  }));


  const columns = [
    {
      field: "variableName",
      headerName: "Variable Name",
      flex: 0.5,
      editable: true,
      cellClassName: "word-wrap",
    },
    {
      field: "value",
      headerName: "Value",
      flex: 1,
      editable: true,
      cellClassName: "word-wrap",
    },
    {
      field: "fieldType",
      headerName: "Type",
      flex: 0.2,
      editable: true,
      type: "singleSelect",
      valueOptions: ["Static", "Dynamic"],
    },
    {
      field: "actions",
      headerName: "Actions",
      flex: 0.3,
      renderCell: (params) => (
        <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
          onClick={() => handleDeleteRow(params.row.id)}
        />
      ),
    },
  ];

  const handleDeleteRow = useCallback(
    (id) => {
      // Filter the row out of the formattedTableData by id
      const updatedTableData = formattedTableData.filter((row) => row.id !== id);
      
      // Update the table view in Redux
      dispatch(updateTableView(updatedTableData));
  
      // Create a copy of the current changeTo state and delete the corresponding key
      const updatedChangeTo = { ...changeToData };
      delete updatedChangeTo[id];
      
      // Dispatch the updated state to Redux
      dispatch(updateChangeTo(updatedChangeTo));
    },
    [dispatch, formattedTableData, changeToData]
  );

  return (
    <Box
      sx={{
        width: "100%",
        borderRadius: "8px",
        overflow: "hidden",
        marginBottom: "10px",
      }}
    >
      {formattedTableData.length > 0 ? (
        <StyledDataGrid
          slots={{ noRowsOverlay: CustomNoRowsComponent }}
          rows={formattedTableData}
          columns={columns}
          getRowHeight={() => "auto"}
          autoHeight
          disableColumnResize
          editMode="row"
          rowModesModel={rowModesModel}
          onRowModesModelChange={(newModel) => setRowModesModel(newModel)}
          processRowUpdate={processRowUpdate}
          experimentalFeatures={{ newEditingApi: true }}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 100 },
            },
          }}
          pageSizeOptions={[50, 100]}
          onProcessRowUpdateError={(error) => {
            console.log("ERROR", error);
          }}
        />
      ) : (
        <Box sx={{ mt: 2 }}>
          No data available. Please pull down beacon data first.
        </Box>
      )}
    </Box>
  );
}
