// UpdateControls.js
import React, { useState } from 'react';
import { Box, Button, Stack, TextField, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { getOneBeaconAsync, beaconUpdateValue, beaconUpdateError, beaconTableView, updateTableView, updateChangeTo } from "../../redux/beacons/update/getOneBeacon"
import { putResponseAsync } from '../../redux/beacons/update/updateBeacon';
import AddRowModal from './AddRowModal';

export default function UpdateControls() {
  const updateOneBeaconData = useSelector(beaconUpdateValue);
  const updateError = useSelector(beaconUpdateError);
  const tableData = useSelector(beaconTableView);

  const [beaconID, setBeaconID] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const dispatch = useDispatch();

  const handleUpdateClick = () => {
    dispatch(putResponseAsync(beaconID, updateOneBeaconData));
  };

  const handleAddRow = (newRow) => {
    const newTableData = tableData.map((row) => ({ ...row }));
    newTableData.push({ id: newTableData.length, ...newRow });
    dispatch(updateTableView(newTableData));

    const newChangeTo = {
      ...updateOneBeaconData,
      [newRow.variableName]: {
        value: newRow.value,
        fieldType: newRow.fieldType,
      },
    };
    dispatch(updateChangeTo(newChangeTo));
  };

  return (
    <>
      <Box sx={{ p: 0.5, width: "100%" }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
          Update Beacon
        </Typography>
        <Stack spacing={2}>
          <TextField
            multiline
            size="small"
            fullWidth
            maxRows={8}
            label="Beacon ID"
            id="beaconID"
            value={beaconID}
            onChange={(e) => setBeaconID(e.target.value)}
            sx={{
              bgcolor: "#FFFFFF",
              borderRadius: 2,
              boxShadow: 1,
            }}
          />
          <Button
            fullWidth
            sx={{
              bgcolor: "#2cc84d",
              color: "white",
              fontWeight: "bold",
              borderRadius: 2,
              textTransform: "none",
              "&:hover": { bgcolor: "#211647" },
            }}
            variant="contained"
            onClick={() => dispatch(getOneBeaconAsync(beaconID))}
            disabled={!/\d/.test(beaconID)} // Disable if there are no digits in beaconID
          >
            Get Truth
          </Button>
          {beaconID.length > 0 && updateError.status !== 404 && tableData.length > 0 ? (
            <>
              <Button
                fullWidth
                sx={{
                  bgcolor: "#2cc84d",
                  color: "white",
                  fontWeight: "bold",
                  borderRadius: 2,
                  textTransform: "none",
                  "&:hover": { bgcolor: "#0b1306" },
                }}
                variant="contained"
                onClick={handleUpdateClick}
              >
                Update Beacon
              </Button>
              <Button
                sx={{
                  bgcolor: "#2cc84d",
                  color: "white",
                  fontWeight: "bold",
                  borderRadius: 2,
                  textTransform: "none",
                  "&:hover": { bgcolor: "#0b1306" },
                }}
                variant="contained"
                onClick={() => setModalOpen(true)}
              >
                Add Row
              </Button>
            </>
          ) : null}
          {updateError.status === 404 && (
            <Typography variant="body2" sx={{ color: "red" }}>
              Beacon ID does not exist.
            </Typography>
          )}
        </Stack>
      </Box>
      <AddRowModal
        open={modalOpen}
        handleClose={() => setModalOpen(false)}
        handleAddRow={handleAddRow}
      />
    </>
  );
}
