// CreateControls.js
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Typography } from "@mui/material";
import { useState } from "react";
import {
  postResponseAsync,
  createBeaconSliceValue,
} from "../../redux/beacons/create/createBeacon";
import BeaconMarketModal from "./BeaconMarketModal"; // Update path accordingly
import { beaconUpdateError } from "../../redux/beacons/update/getOneBeacon"; // Import error selector

export default function CreateControls() {
  const createBeaconState = useSelector(createBeaconSliceValue);
  const beaconError = useSelector(beaconUpdateError); // Select beacon error state
  const dispatch = useDispatch();

  const [beaconID, setBeaconID] = useState("");
  const [isMarketModalOpen, setIsMarketModalOpen] = useState(false);

  const POSTReady = {};
  Object.keys(createBeaconState.beaconData).forEach((id) => {
    POSTReady[createBeaconState.beaconData[id].variableName] = {
      value: createBeaconState.beaconData[id].currentValue || "", // Include even if currentValue is empty
      fieldType: createBeaconState.beaconData[id].fieldType || "Static", // Default to "Static" if fieldType is empty
    };
  });

  const handleCreateBeacon = () => {
    if (
      Object.keys(POSTReady).length > 0 &&
      !Object.values(createBeaconState.error).includes(true)
    ) {
      console.log("POSTReady has a length of: ", Object.keys(POSTReady).length, POSTReady);
      dispatch(postResponseAsync(beaconID, POSTReady));
    } else {
      console.log("Create Failed; Empty Cell Detected");
    }
  };

  return (
    <Box
      sx={{
        p: 0.5,
        width: "100%",
      }}
    >
      <Typography variant="h6" sx={{ fontWeight: "bold", mb: 2 }}>
        Create New Beacon
      </Typography>
      <Stack spacing={2}>
        <TextField
          multiline
          size="small"
          fullWidth
          maxRows={8}
          label="Enter New Beacon ID"
          id="beaconID"
          value={beaconID}
          onChange={(e) => setBeaconID(e.target.value)}
          sx={{
            bgcolor: "#FFFFFF",
            borderRadius: 2,
            boxShadow: 1,
          }}
        />

        <Button
          fullWidth
          sx={{
            bgcolor: "#2cc84d",
            color: "white",
            fontWeight: "bold",
            borderRadius: 2,
            textTransform: "none",
            "&:hover": { bgcolor: "#0b1306" },
          }}
          variant="contained"
          onClick={handleCreateBeacon}
          disabled={!beaconID} // Disable if beaconID is empty
        >
          Create Beacon
        </Button>

        <Button
          fullWidth
          sx={{
            bgcolor: "#2cc84d",
            color: "white",
            fontWeight: "bold",
            borderRadius: 2,
            textTransform: "none",
            "&:hover": { bgcolor: "#0b1306" },
          }}
          variant="contained"
          onClick={() => setIsMarketModalOpen(true)}
        >
          Load Beacon Data
        </Button>

        <BeaconMarketModal
          open={isMarketModalOpen}
          onClose={() => setIsMarketModalOpen(false)}
        />

        {createBeaconState.response && (
          <Typography
            variant="body2"
            sx={{ color: createBeaconState.response.success ? "green" : "red" }}
          >
            {createBeaconState.response.message}
          </Typography>
        )}

        {beaconError?.message && (
          <Typography variant="body2" sx={{ color: "red" }}>
            {beaconError.message}
          </Typography>
        )}

        {(!Object.keys(POSTReady).length > 0 ||
          Object.values(createBeaconState.error).includes(true)) && (
          <Typography variant="body2" sx={{ color: "red" }}>
            Variables must not have an empty value.
          </Typography>
        )}
      </Stack>
    </Box>
  );
}
