import { useSelector, useDispatch } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import Box from "@mui/material/Box";
import { createBeaconData, setError } from '../../../redux/beacons/create/createBeacon';
import { beaconTableView } from '../../../redux/beacons/update/getOneBeacon';
import { useEffect } from "react";

const columns = [
  { field: "variableName", headerName: "Variable Name", flex: 0.5, editable: true, cellClassName: 'word-wrap' },
  { field: "currentValue", headerName: "Value", flex: 1, editable: true, cellClassName: 'word-wrap' },
  { field: "fieldType", headerName: "Field Type", flex: 0.2, editable: true, cellClassName: 'word-wrap', type: 'singleSelect', valueOptions: ['Static', 'Dynamic'] }
];

export default function BeaconCreateContainer() {
  const dispatch = useDispatch();
  const fetchedBeaconData = useSelector(beaconTableView);

  // Populate rows based on fetched beacon data, default to empty rows if none
  const rows = Array.isArray(fetchedBeaconData) && fetchedBeaconData.length > 0
  ? fetchedBeaconData
  : Array.from({ length: 100 }, (_, index) => ({
      id: index,
      variableName: "",
      currentValue: "",
      fieldType: "",
    }));


  // Use useEffect to populate createBeacon state when fetchedBeaconData changes
  useEffect(() => {
    if (Array.isArray(fetchedBeaconData) && fetchedBeaconData.length > 0) {
      fetchedBeaconData.forEach((row) => {
        const newBeacon = {
          id: row.id,
          variableName: row.variableName,
          currentValue: row.currentValue,
          fieldType: row.fieldType,
        };
        dispatch(createBeaconData(newBeacon));
      });
    }
  }, [fetchedBeaconData, dispatch]);
  

  return (
    <Box sx={{ p: 3, bgcolor: "#F9FAFB", borderRadius: 2, boxShadow: 1, width: "100%" }}>
      <DataGrid
        sx={{
          bgcolor: "#FFFFFF",
          borderRadius: 2,
          boxShadow: 1,
          "& .MuiDataGrid-columnHeaders": { bgcolor: "#F1F3F5" },
          "& .MuiDataGrid-cell": { bgcolor: "#FFFFFF" },
          "& .MuiDataGrid-footerContainer": { bgcolor: "#F1F3F5" }
        }}
        rows={rows}
        columns={columns}
        autoHeight
        rowHeight={40}
        disableColumnResize
        initialState={{
          pagination: {
            paginationModel: { pageSize: 100 },
          },
        }}
        pageSizeOptions={[50, 100]}
        processRowUpdate={(updatedRow) => {
          const newBeacon = {
            id: updatedRow.id,
            variableName: updatedRow.variableName,
            currentValue: updatedRow.currentValue,
            fieldType: updatedRow.fieldType,
          };

          if ((updatedRow.variableName.length > 0 && (updatedRow.currentValue.length <= 0 || updatedRow.fieldType.length <= 0)) ||
              (updatedRow.variableName.length <= 0 && (updatedRow.currentValue.length > 0 && updatedRow.fieldType.length > 0))) {
            dispatch(setError({
              id: updatedRow.id,
              error: true,
            }));
          } else {
            dispatch(setError({
              id: updatedRow.id,
              error: false,
            }));
            dispatch(createBeaconData(newBeacon));
          }
          return updatedRow;
        }}
        onProcessRowUpdateError={(error) => {
          console.log("ERROR", error);
        }}
      />
    </Box>
  );
}
