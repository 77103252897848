import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const getOneBeaconSlice = createSlice({
  name: "beaconUpdate",
  initialState: {
    beaconID: "",
    changeTo: {},
    beaconTableView: [],
    error: {},
  },
  reducers: {
    updateOneBeaconID: (state, action) => {
      state.beaconID = action.payload;
    },
    updateChangeTo: (state, action) => {
      state.changeTo = action.payload;
    },
    updateTableView: (state, action) => {
      state.beaconTableView = action.payload;
    },
    reset: (state) => {
      state.beaconID = "";
      state.changeTo = {};
      state.beaconTableView = [];
      state.error = {};
    },
    updateError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  updateTableView,
  updateOneBeaconID,
  reset,
  updateChangeTo,
  updateError,
} = getOneBeaconSlice.actions;

export const getOneBeaconAsync = (beacon, marketCode) => async (dispatch) => {
  dispatch(reset());
  const selectedMarketCode = marketCode || JSON.parse(localStorage.getItem("market")).code;

  try {
    const res = await axios.get(
      `https://mcdapi.cxsandbox.com/truth-inbound?beacon_id=${beacon}&market=${selectedMarketCode}`
    );
    const sanitizedData = res.data;

    dispatch(updateChangeTo(sanitizedData));
    dispatch(updateOneBeaconID(beacon));

    let tableData = [];
    let tableIndex = 0;

    for (let [key, value] of Object.entries(sanitizedData).filter(([key]) => key !== "market")) {
      let currentValue = "";
      let fieldType = "Static";

      if (typeof value === "object" && value !== null && "value" in value && "fieldType" in value) {
        currentValue = value.value;
        fieldType = value.fieldType;
      } else if (value === null) {
        currentValue = "";
        fieldType = "Static";
      } else {
        currentValue = value;
      }

      tableData.push({
        id: tableIndex,
        variableName: key,
        currentValue: currentValue,
        fieldType: fieldType,
      });

      tableIndex++;
    }

    dispatch(updateTableView(tableData));
  } catch (err) {
    console.error(err.response?.data);
    const errorMessage = "Beacon not found in selected market";
    dispatch(updateError({ message: errorMessage }));
  }
};
export const beaconUpdateValue = (state) => state.getOneBeacon.changeTo;
export const beaconTableView = (state) => state.getOneBeacon.beaconTableView;
export const beaconUpdateBeaconID = (state) => state.getOneBeacon.beaconID;
export const beaconUpdateError = (state) => state.getOneBeacon.error;
export default getOneBeaconSlice.reducer;
