import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const createBeaconSlice = createSlice({
  name: "createBeacon",
  initialState: {
    beaconData: {},
    response: null, // Updated to hold success/failure messages
    beaconID: "",
    error: {},
  },
  reducers: {
    createBeaconData: (state, action) => {
      const { id, currentValue, variableName, fieldType } = action.payload;
      state.beaconData = {
        ...state.beaconData,
        [id]: { currentValue, variableName, fieldType },
      };
    },
    createBeaconID: (state, action) => {
      state.beaconID = action.payload;
    },
    reset: (state) => {
      state.beaconData = {};
      state.response = null; // Reset response message
      state.beaconID = "";
    },
    postResponse: (state, action) => {
      state.response = action.payload; // Store response message (success/failure)
    },
    setError: (state, action) => {
      state.error = {
        ...state.error,
        [action.payload.id]: action.payload.error,
      };
    },
  },
});

export const {
  createBeaconData,
  reset,
  postResponse,
  createBeaconID,
  setError,
} = createBeaconSlice.actions;

export const postResponseAsync = (beacon, data) => async (dispatch) => {
  const marketCode = JSON.parse(localStorage.getItem("market")).code;
  data.market = marketCode;

  try {
    const res = await axios.post(
      `https://mcdapi.cxsandbox.com/truth-inbound?beacon_id=${beacon}&market=${marketCode}`,
      data
    );
    // Only dispatch the serializable response status
    dispatch(postResponse({ message: "Beacon created successfully", success: true }));
  } catch (err) {
    console.error(err);
    dispatch(postResponse({ message: "Beacon creation failed", success: false }));
  }
};

export const createBeaconSliceValue = (state) => state.createBeacon;
export default createBeaconSlice.reducer;
